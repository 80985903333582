import { TitanCampaignAttribution } from '@cognitiv/galaxy-api';

export const titan_campaign_attribution_default: TitanCampaignAttribution = {
  has_activated: false,
  start_date: '',
  start_date_formatted: '',
  secondary_start_date: '',
  secondary_start_date_formatted: '',
  finish_date: '',
  finish_date_formatted: '',
  date: '',
  date_formatted: '',
  revenue_field_mapping: '',
  order_id_field_mapping: '',
  secondary_kpi_attribution_non_null: false,
  attribution_configuration_id: 0,
  primary_campaign_id: 0,
  primary_campaign_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  campaign_ids: [],
  event_source_ids: [],
  attribution_model_id: 0,
  attribution_model_name: '',
  lookback_days: 0,
  lookback_days_formatted: '0',
  lookback_days_secondary: null,
  lookback_days_secondary_formatted: '',
  attribution_multiplier: 1,
  attribution_multiplier_formatted: '1',
  attribution_multiplier_secondary: 1,
  attribution_multiplier_secondary_formatted: '1',
  model_attribution: false,
  primary_kpi_attribution: false,
  secondary_kpi_attribution: false,
  attribution_identity_type_id: 0,
  attribution_identity_type_name: '',
  attribution_identity_join_column_name: '',
  attribution_identity_type_name_formatted: '',
  backfill_days: null,
  backfill_days_formatted: '',
  backfill_completed: false,
  is_active: false,
  share_campaign_attribution: false,
  revision_id: 0,
  uuid: '',
  attribution_state_name: '',
  attribution_state_id: 0,
  kpi_attribution_type_id: 0,
  kpi_attribution_type_name: '',
  campaigns: [],
  event_sources: [],
  field_mappings: [],
};
