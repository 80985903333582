import { HyperionInboundReport } from '@cognitiv/galaxy-api';

export const hyperion_inbound_report_default: HyperionInboundReport = {
  inbound_report_id: 0,
  s3_bucket: '',
  s3_object_key: '',
  file_name: '',
  inbound_report_state_id: 0,
  inbound_report_state_name: '',
  inbound_config_id: 0,
  inbound_config_name: '',
  inbound_email_id: 0,
  email_identifier: 0,
  email_timestamp_utc: '',
  email_sender: '',
  email_subject: '',
  created_by_user_id: 0,
  created_date: '',
  import_state_id: 0,
  import_state_name: '',
  failure_reason: '',
  import_config_id: 0,
  importer_id: 0,
  importer_name: '',
  created_date_formatted: '',
  uuid: '',
  rows_imported: 0,
  rows_imported_formatted: '',
  rows_processed: 0,
  rows_processed_formatted: '',
  import_state_name_formatted: '',
  import_state_date: '',
  import_state_date_formatted: '',
  inbound_report_state_name_formatted: '',
  file_size_bytes: 0,
  file_size_bytes_formatted: '',
  email_timestamp_formatted: '',
};
