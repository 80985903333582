import { TitanCampaign } from '@cognitiv/galaxy-api';

export const titan_campaign_default: TitanCampaign = {
  campaign_id: 0,
  campaign_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  partner_id: 0,
  partner_name: '',
  product_type_id: 0,
  product_type_name: '',
  kpi_type_id: null,
  kpi_type_name: '',
  kpi_benchmark: 0,
  kpi_benchmark_formatted: '',
  kpi_benchmark_abbr_formatted: '',
  kpi_unit_id: null,
  kpi_unit_name: '',
  campaign_state_id: 0,
  campaign_state_name: '',
  conversion_source_id: null,
  conversion_source_name: '',
  delivery_source_id: null,
  delivery_source_name: '',
  revision_id: 0,
  uuid: '',
  opportunity_size_usd: null,
  opportunity_size_usd_formatted: '',
  opportunity_size_usd_abbr_formatted: '',
};
