import { TitanNotification } from '@cognitiv/galaxy-api';

export const titan_notification_default: TitanNotification = {
  subscription_id: 0,
  user_id: 0,
  subscription_configuration_id: 0,
  subscription_configuration_name: '',
  subscription_type_category_id: 0,
  subscription_type_category_name: '',
  subscription_type_id: 0,
  subscription_type_name: '',
  state_id: 0,
  subscription_name: '',
  state_name: '',
  conditions: [],
  delivery_destinations: [],
  uuid: '',
  delivery_destinations_formatted: '',
};
