import { GeminiCampaign } from '@cognitiv/galaxy-api';
import { TITAN_DELIVERY_SOURCE_NAME } from 'products/titan/constants';
import { TITAN_DELIVERY_SOURCE_ID } from 'products/titan/enums';

export const gemini_campaign_default: GeminiCampaign = {
  campaign_id: 0,
  campaign_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  partner_id: 0,
  partner_name: '',
  product_type_id: 0,
  product_type_name: '',
  kpi_type_id: null,
  kpi_type_name: '',
  kpi_unit_id: null,
  kpi_unit_name: '',
  kpi_benchmark: null,
  campaign_state_id: 0,
  campaign_state_name: '',
  conversion_source_id: TITAN_DELIVERY_SOURCE_ID.ADSERVER,
  conversion_source_name: TITAN_DELIVERY_SOURCE_NAME[TITAN_DELIVERY_SOURCE_ID.ADSERVER],
  delivery_source_id: TITAN_DELIVERY_SOURCE_ID.ADSERVER,
  delivery_source_name: TITAN_DELIVERY_SOURCE_NAME[TITAN_DELIVERY_SOURCE_ID.ADSERVER],
  revision_id: 0,
  opportunity_size_usd: null,
  primary_event_source_id: null,
  cpm_usd: null,
  kpi_benchmark_formatted: '',
  kpi_benchmark_abbr_formatted: '',
  opportunity_size_usd_formatted: '',
  opportunity_size_usd_abbr_formatted: '',
  cpm_usd_abbr_formatted: '',
  identity_graph_type_id: 0,
  identity_graph_type_name: '',
  revenue_type_id: 0,
  revenue_type_name: '',
  primary_event_source_name: '',
  primary_event_source_type_id: null,
  primary_event_source_type_name: '',
  finish_offset_hours: null,
  finish_offset_hours_formatted: '',
  supplemental_event_source_ids: [],
  uuid: '',
};
