import { TitanSalesforceFlight } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanSalesforceFlight[] = [];

export const titanSalesforceFlightsSlice = createSlice({
  name: SLICE.TITAN_SALESFORCE_FLIGHTS,
  initialState,
  reducers: {
    clearTitanSalesforceFlights: () => initialState,
    setTitanSalesforceFlights: (_, action: PayloadAction<TitanSalesforceFlight[]>) => action.payload,
  },
});

export const { clearTitanSalesforceFlights, setTitanSalesforceFlights } = titanSalesforceFlightsSlice.actions;

export default titanSalesforceFlightsSlice.reducer;
