import { LIBRA_DEAL_ACTION_OPTION_ID, LIBRA_DEAL_MARGIN_TYPE_ID, LIBRA_DEAL_STATE_ID } from 'products/libra/enums';
import { LibraDealActionOptionType, LibraDealMarginTypeOptionType } from 'products/libra/types';
import { STATE_THEME } from 'theme/theme';

export const LIBRA_DEAL_STATE_COLOR = {
  [LIBRA_DEAL_STATE_ID.CREATED]: STATE_THEME.created,
  [LIBRA_DEAL_STATE_ID.CONFIGURING]: STATE_THEME.configuring,
  [LIBRA_DEAL_STATE_ID.INACTIVE]: STATE_THEME.deal_inactive,
  [LIBRA_DEAL_STATE_ID.LIVE_PENDING]: STATE_THEME.live_pending,
  [LIBRA_DEAL_STATE_ID.LIVE]: STATE_THEME.live,
  [LIBRA_DEAL_STATE_ID.OFFLINE]: STATE_THEME.offline,
  [LIBRA_DEAL_STATE_ID.UNHEALTHY]: STATE_THEME.unhealthy,
  [LIBRA_DEAL_STATE_ID.AWAITING_MANUAL_DEAL_CREATION]: STATE_THEME.AWAITING_MANUAL_DEAL_CREATION,
  [LIBRA_DEAL_STATE_ID.AWAITING_SSP_SEAT]: STATE_THEME.AWAITING_SSP_SEAT,
  [LIBRA_DEAL_STATE_ID.ARCHIVED]: STATE_THEME.archived,
  [LIBRA_DEAL_STATE_ID.FAILED]: STATE_THEME.failed,
};

export const libra_deal_action_options: LibraDealActionOptionType[] = [
  {
    label: 'Edit',
    id: LIBRA_DEAL_ACTION_OPTION_ID.EDIT,
  },
  {
    label: 'Activate',
    id: LIBRA_DEAL_ACTION_OPTION_ID.ACTIVATE,
  },
  {
    label: 'Deactivate',
    id: LIBRA_DEAL_ACTION_OPTION_ID.DEACTIVATE,
  },
  {
    label: 'Archive',
    id: LIBRA_DEAL_ACTION_OPTION_ID.ARCHIVE,
  },
  {
    label: 'Update Magnite Deal Identifier',
    id: LIBRA_DEAL_ACTION_OPTION_ID.UPDATE_MAGNITE_DEAL_IDENTIFIER,
  },
  {
    label: 'Retry Deal Creation',
    id: LIBRA_DEAL_ACTION_OPTION_ID.RETRY_DEAL_CREATION,
  },
];

export const deals_page_csv_headers = {
  client_deal_identifier_formatted: 'Deal Identifier',
  deal_name: 'Deal',
  campaign_name: 'Campaign',
  dsp_seat_identifier_formatted: 'DSP Seat Identifier',
  creative_type_name: 'Creative Type',
  inventory_source_name: 'SSP',
  deal_state_name: 'Status',
  spend_usd_formatted: 'Spend',
  spend_usd_yesterday_formatted: 'Last Day Spend',
  win_rate_formatted: 'Win Rate',
  win_rate_yesterday_formatted: 'Last Day Win Rate',
  bid_rate_formatted: 'Bid Rate',
  bid_rate_yesterday_formatted: 'Last Day Bid Rate',
  bid_requests_formatted: 'Bid Requests',
  bid_requests_yesterday_formatted: 'Last Day Bid Requests',
  impressions_formatted: 'Impressions',
  impressions_yesterday_formatted: 'Last Day Impressions',
};

export const campaign_deals_page_csv_headers = {
  client_deal_identifier_formatted: 'Deal Identifier',
  deal_name: 'Deal',
  dsp_seat_identifier_formatted: 'DSP Seat Identifier',
  creative_type_name: 'Creative Type',
  inventory_source_name: 'SSP',
  deal_state_name: 'Status',
  spend_usd_formatted: 'Spend',
  spend_usd_yesterday_formatted: 'Last Day Spend',
  win_rate_formatted: 'Win Rate',
  win_rate_yesterday_formatted: 'Last Day Win Rate',
  bid_rate_formatted: 'Bid Rate',
  bid_rate_yesterday_formatted: 'Last Day Bid Rate',
  bid_requests_formatted: 'Bid Requests',
  bid_requests_yesterday_formatted: 'Last Day Bid Requests',
  impressions_formatted: 'Impressions',
  impressions_yesterday_formatted: 'Last Day Impressions',
};

export const LIBRA_DEAL_MARGIN_TYPE: Record<LIBRA_DEAL_MARGIN_TYPE_ID, string> = {
  [LIBRA_DEAL_MARGIN_TYPE_ID.PERCENTAGE]: 'Percentage',
  [LIBRA_DEAL_MARGIN_TYPE_ID.FLAT]: 'Flat',
};

export const libra_deal_margin_type_options: LibraDealMarginTypeOptionType[] = [
  {
    label: LIBRA_DEAL_MARGIN_TYPE[LIBRA_DEAL_MARGIN_TYPE_ID.PERCENTAGE],
    id: LIBRA_DEAL_MARGIN_TYPE_ID.PERCENTAGE,
    uuid: 1,
  },
  {
    label: LIBRA_DEAL_MARGIN_TYPE[LIBRA_DEAL_MARGIN_TYPE_ID.FLAT],
    id: LIBRA_DEAL_MARGIN_TYPE_ID.FLAT,
    uuid: 2,
  },
];
