import { TitanAdvertiser } from '@cognitiv/galaxy-api';

export const titan_advertiser_default: TitanAdvertiser = {
  advertiser_name: '',
  advertiser_id: 0,
  partner_name: '',
  partner_id: 0,
  iab_category_name: '',
  iab_category_id: null,
  domain_url: '',
  state_id: 0,
  state_name: '',
  created_by: '',
  created_date: '',
  created_date_formatted: '',
  uuid: '',
  created_by_user_id: 0,
  hyperion_error: false,
  impressions_today_abbr_formatted: '',
  impressions_year_abbr_formatted: '',
  impressions_yesterday_abbr_formatted: '',
  spend_usd_today_abbr_formatted: '',
  spend_usd_year_abbr_formatted: '',
  spend_usd_yesterday_abbr_formatted: '',
  impressions_today: null,
  impressions_year: null,
  impressions_yesterday: null,
  spend_usd_today: null,
  spend_usd_year: null,
  spend_usd_yesterday: null,
};
