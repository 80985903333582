import { OrionPositivArticle } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/orion/operators/slice';

const initialState: OrionPositivArticle[] = [];

export const orionPositivArticlesSlice = createSlice({
  name: SLICE.ORION_POSITIV_ARTICLES,
  initialState,
  reducers: {
    clearOrionPositivArticles: () => [],
    setOrionPositivArticles: (_, action: PayloadAction<OrionPositivArticle[]>) => action.payload,
  },
});

export const { clearOrionPositivArticles, setOrionPositivArticles } = orionPositivArticlesSlice.actions;

export default orionPositivArticlesSlice.reducer;
