export const PUBLIC = {
  ROOT: {
    path: '/',
    element: null,
  },
  LOGIN: {
    path: '/login',
    element: null,
  },
  PASSWORD_REQUEST: {
    path: '/request-password',
    element: null,
  },
  PASSWORD_RESET: {
    path: '/reset-password/:auth_token',
    element: null,
  },
};

export const PRIVATE = {
  ROOT: {
    path: '/',
    element: null,
  },
};

export const ORION = {
  APPLICATION: {
    path: '/applications/contextual',
    element: '',
    page_type_id: null,
    helmet: 'Contextual Application',
  },
  POSITIV: {
    path: '/applications/positiv',
    element: '',
    page_type_id: null,
  },
  DEFAULT: {
    path: '',
    element: 'products/orion/pages/campaigns/CampaignsPage',
    page_type_id: 9,
    helmet: 'Contextual Campaigns',
  },
  DOMAIN_LISTS: {
    path: 'domain-lists',
    element: 'products/orion/pages/domain-lists/DomainListsPage',
    page_type_id: 11,
    helmet: 'Contextual Segment Domain Lists',
  },
  DOMAIN_LIST: {
    path: 'domain-lists/:domain_list_id/domains',
    element: 'products/orion/pages/domain-list/DomainListPage',
    page_type_id: 13,
    helmet: 'Contextual Segment Domain List',
  },
  CAMPAIGNS: {
    path: 'campaigns',
    element: 'products/orion/pages/campaigns/CampaignsPage',
    page_type_id: 9,
    helmet: 'Contextual Campaigns',
  },
  CAMPAIGN: {
    path: 'campaigns/:campaign_id',
    element: 'products/orion/pages/campaign/template/CampaignTemplate',
    page_type_id: 12,
    helmet: 'Contextual Campaign',
    DEFAULT: {
      path: '',
      element: 'products/orion/pages/campaign/folders/performance/PerformancePage',
      page_type_id: null,
    },
    SEGMENTS: {
      path: 'segments',
      element: 'products/orion/pages/campaign/folders/segments/SegmentsPage',
      page_type_id: null,
    },
    DEALS: {
      path: 'deals',
      element: 'products/orion/pages/campaign/folders/deals/DealsPage',
      page_type_id: null,
    },
    PERFORMANCE: {
      path: 'performance',
      element: 'products/orion/pages/campaign/folders/performance/PerformancePage',
      page_type_id: null,
    },
    REPORTS: {
      path: 'reports',
      element: 'products/orion/pages/campaign/folders/reports/ReportsPage',
      page_type_id: null,
    },
    DEAL_MAPPINGS: {
      path: 'deal-mappings',
      element: 'products/orion/pages/campaign/folders/dsp-line-item-deal-mappings/DspLineItemDealMappingsPage',
      page_type_id: null,
    },
    NOTES: {
      path: 'notes',
      element: 'products/orion/pages/campaign/folders/campaign-notes/CampaignNotesPage',
      page_type_id: null,
    },
  },
  SEGMENTS: {
    path: 'segments',
    element: 'products/orion/pages/segments/SegmentsPage',
    page_type_id: 10,
    helmet: 'Contextual Segments',
  },
  SEGMENT: {
    path: 'segments/:segment_id',
    element: 'products/orion/pages/segment/template/SegmentTemplate',
    page_type_id: 36,
    helmet: 'Contextual Segment',
    queries: { reference_text_type_id: 1, is_excluded: false },
    DEFAULT: {
      path: '',
    },
    FILTERS: {
      path: 'filters',
    },
  },
  DEALS: {
    path: 'deals',
    element: 'products/orion/pages/deals/DealsPage',
    page_type_id: 8,
    helmet: 'Contextual Deals',
  },
  DEAL: {
    path: 'deals/:deal_id',
    element: 'products/orion/pages/deal/template/DealTemplate',
    page_type_id: 35,
    helmet: 'Contextual Deal',
    DEFAULT: {
      path: '',
      element: 'products/orion/pages/deal/folders/filter/FilterPage',
      page_type_id: null,
    },
    FILTER: {
      path: 'filter',
      element: 'products/orion/pages/deal/folders/filter/FilterPage',
      page_type_id: null,
    },
    SSP_REPORT: {
      path: 'ssp-report',
      element: 'products/orion/pages/deal/folders/ssp-report/SspReportPage',
      page_type_id: null,
    },
  },
  ARTICLES: {
    path: 'articles',
    element: 'products/orion/pages/positiv-articles/PositivArticlesPage',
    helmet: 'Positiv Articles',
    page_type_id: 38,
  },
};

export const VIRGO = {
  APPLICATION: {
    path: '/applications/curation',
    element: '',
    page_type_id: null,
    helmet: 'Curation Application',
  },
  DEFAULT: {
    path: '',
    element: 'products/virgo/pages/campaigns/CampaignsPage',
    page_type_id: 3,
    helmet: 'Curation Campaigns',
  },
  DEALS: {
    path: 'deals',
    element: 'products/virgo/pages/deals/DealsPage',
    page_type_id: 2,
    helmet: 'Curation Deals',
  },
  DEAL: {
    path: 'deals/:deal_id',
    element: 'products/virgo/pages/deal/template/DealTemplate',
    page_type_id: 34,
    helmet: 'Curation Deal',
    DEFAULT: {
      path: '',
      element: 'products/virgo/pages/deal/folders/filter/FilterPage',
      page_type_id: null,
    },
    FILTER: {
      path: 'filter',
      element: 'products/virgo/pages/deal/folders/filter/FilterPage',
      page_type_id: null,
    },
    SSP_REPORT: {
      path: 'ssp-report',
      element: 'products/virgo/pages/deal/folders/ssp-report/SspReportPage',
      page_type_id: null,
    },
  },
  CAMPAIGNS: {
    path: 'campaigns',
    element: 'products/virgo/pages/campaigns/CampaignsPage',
    page_type_id: 3,
    helmet: 'Curation Campaigns',
  },
  CAMPAIGN: {
    path: 'campaigns/:campaign_id',
    element: 'products/virgo/pages/campaign/template/CampaignTemplate',
    page_type_id: 4,
    helmet: 'Curation Campaign',
    DEFAULT: {
      path: '',
      element: 'products/virgo/pages/campaign/folders/performance/PerformancePage',
      page_type_id: null,
    },
    DEALS: {
      path: 'deals',
      element: 'products/virgo/pages/campaign/folders/deals/DealsPage',
      page_type_id: null,
    },
    PERFORMANCE: {
      path: 'performance',
      element: 'products/virgo/pages/campaign/folders/performance/PerformancePage',
      page_type_id: null,
    },
    REPORTS: {
      path: 'reports',
      element: 'products/virgo/pages/campaign/folders/reports/ReportsPage',
      page_type_id: null,
    },
    DEAL_MAPPINGS: {
      path: 'deal-mappings',
      element: 'products/virgo/pages/campaign/folders/dsp-line-item-deal-mappings/DspLineItemDealMappingsPage',
      page_type_id: null,
    },
    NOTES: {
      path: 'notes',
      element: 'products/virgo/pages/campaign/folders/campaign-notes/CampaignNotesPage',
      page_type_id: null,
    },
  },
};

export const LIBRA = {
  APPLICATION: {
    path: '/applications',
    element: 'products/libra/pages/application/template/ApplicationTemplate',
    page_type_id: null,
  },
  DSP_SEAT_IDENTIFIERS: {
    path: 'dsp-identifiers',
    element: 'products/libra/pages/dsp-seat-identifiers/DspSeatIdentifiersPage',
    page_type_id: 22,
    helmet: 'DSP Seat Identifiers',
  },
  DOMAIN_LISTS: {
    path: 'domain-lists',
    element: 'products/libra/pages/domain-lists/DomainListsPage',
    page_type_id: 41,
    helmet: 'Domain Lists',
  },
};

export const TITAN = {
  APPLICATION: {
    path: '/applications',
    element: 'products/titan/pages/application/template/ApplicationTemplate',
    page_type_id: null,
  },
  ADVERTISERS: {
    path: 'advertisers',
    element: 'products/titan/pages/advertisers/AdvertisersPage',
    page_type_id: 6,
    helmet: 'Advertisers',
  },
  PARTNERS: {
    path: 'partners',
    element: 'products/titan/pages/partners/PartnersPage',
    page_type_id: 5,
    helmet: 'Partners',
  },
  NOTIFICATIONS: {
    path: 'notifications',
    element: 'products/titan/pages/notifications/NotificationsPage',
    page_type_id: 45,
    helmet: 'Notifications',
  },
  EVENT_SOURCES: {
    path: 'event-sources',
    element: 'products/titan/pages/event-sources/EventSourcesPage',
    page_type_id: 14,
    helmet: 'Event Sources',
  },
  UNIVERSAL_PIXEL: {
    path: 'event-sources/universal-pixels/:event_source_id',
    element: 'products/titan/pages/universal-pixel/UniversalPixelPage',
    page_type_id: 27,
    helmet: 'Universal Pixel',
    DEFAULT: {
      path: '',
      element: 'products/titan/pages/universal-pixel/folders/properties/PropertiesPage',
      page_type_id: null,
    },
    PROPERTIES: {
      path: 'properties',
      element: 'products/titan/pages/universal-pixel/folders/properties/PropertiesPage',
      page_type_id: null,
    },
    VIRTUAL_EVENTS: {
      path: 'virtual-events',
      element: 'products/titan/pages/universal-pixel/folders/virtual-events/VirtualEventsPage',
      page_type_id: null,
    },
  },
  INDIVIDUAL_PIXEL: {
    path: 'event-sources/individual-pixels/:event_source_id',
    element: 'products/titan/pages/individual-pixel/IndividualPixelPage',
    page_type_id: 28,
    helmet: 'Individual Pixel',
    DEFAULT: {
      path: '',
      element: 'products/titan/pages/individual-pixel/folders/virtual-events/VirtualEventsPage',
      page_type_id: null,
    },
    VIRTUAL_EVENTS: {
      path: 'virtual-events',
      element: 'products/titan/pages/individual-pixel/folders/virtual-events/VirtualEventsPage',
      page_type_id: null,
    },
  },
  VIRTUAL_EVENT: {
    path: 'event-sources/virtual-events/:event_source_id',
    element: 'products/titan/pages/virtual-event/VirtualEventPage',
    page_type_id: 32,
    helmet: 'Virtual Event',
    DEFAULT: {
      path: '',
      element: 'products/titan/pages/virtual-event/folders/conditions/CondtionsPage',
      page_type_id: null,
    },
    CONDITIONS: {
      path: 'conditions',
      element: 'products/titan/pages/virtual-event/folders/conditions/CondtionsPage',
      page_type_id: null,
    },
  },
  LOCATION: {
    path: 'event-sources/location-events/:event_source_id',
    element: 'products/titan/pages/location-event/LocationEventPage',
    page_type_id: 30,
    helmet: 'Location Event',
    DEFAULT: {
      path: '',
      element: 'products/titan/pages/location-event/folders/properties/PropertiesPage',
      page_type_id: null,
    },
    PROPERTIES: {
      path: 'properties',
      element: 'products/titan/pages/location-event/folders/properties/PropertiesPage',
      page_type_id: null,
    },
    VIRTUAL_EVENTS: {
      path: 'virtual-events',
      element: 'products/titan/pages/location-event/folders/virtual-events/VirtualEventsPage',
      page_type_id: null,
    },
  },
  CPG: {
    path: 'event-sources/cpg-events/:event_source_id',
    element: 'products/titan/pages/cpg-event/CpgEventPage',
    page_type_id: 30,
    helmet: 'CPG Event',
    DEFAULT: {
      path: '',
      element: 'products/titan/pages/cpg-event/folders/properties/PropertiesPage',
      page_type_id: null,
    },
    PROPERTIES: {
      path: 'properties',
      element: 'products/titan/pages/cpg-event/folders/properties/PropertiesPage',
      page_type_id: null,
    },
    VIRTUAL_EVENTS: {
      path: 'virtual-events',
      element: 'products/titan/pages/cpg-event/folders/virtual-events/VirtualEventsPage',
      page_type_id: null,
    },
  },
  CREATIVES: {
    path: 'creatives',
    helmet: 'Creatives',
    element: 'products/titan/pages/creatives/CreativesPage',
    page_type_id: 42,
  },
  CREATIVE: {
    path: 'creatives/:creative_id',
    element: 'products/titan/pages/creative/CreativeTemplate',
    helmet: 'Creatives',
    page_type_id: 43,
    DEFAULT: {
      path: '',
      element: 'products/titan/pages/creative/folders/expanded-creative/ExpandedCreativePage',
      page_type_id: null,
    },
    EXPANDED_CREATIVE: {
      path: 'expanded-creative',
      element: 'products/titan/pages/creative/folders/expanded-creative/ExpandedCreativePage',
      helmet: 'Expanded Creative',
      page_type_id: null,
    },
    ADDONS: {
      path: 'addons',
      element: 'products/titan/pages/creative/folders/addons/AddonsPage',
      helmet: 'Addons',
      page_type_id: null,
    },
    EVENT_TESTER: {
      path: 'event-tester',
      element: 'products/titan/pages/creative/folders/event-tester/EventTesterPage',
      helmet: 'Event Tester',
      page_type_id: null,
    },
    THIRD_PARTY_TESTER: {
      path: 'third-party-tester',
      element: 'products/titan/pages/creative/folders/third-party-tester/ThirdPartyTesterPage',
      helmet: 'Third Party Tester',
      page_type_id: null,
    },
    EVENT_LOG: {
      path: 'event-log',
      element: 'products/titan/pages/creative/folders/event-log/EventLogPage',
      helmet: 'Event Log',
      page_type_id: null,
    },
    AUDIT_LOG: {
      path: 'audit-log',
      element: 'products/titan/pages/creative/folders/audit-log/AuditLogPage',
      helmet: 'Audit Log',
      page_type_id: null,
    },
  },
};

export const LUNA = {
  APPLICATION: {
    path: '/applications',
    element: 'products/luna/pages/dashboard/LunaDashboardPage',
    page_type_id: null,
  },
};

export const HYPERION = {
  APPLICATION: {
    path: '/applications/reporting',
    element: '',
    page_type_id: null,
    DEFAULT: {
      path: '/applications',
      element: '',
    },
  },
  CUSTOM_REPORTS: {
    path: 'reports',
    element: 'products/hyperion/pages/custom-reports/CustomReportsPage',
    page_type_id: 7,
    helmet: 'Custom Reports',
  },
  CS_CAMPAIGN_TRACKER: {
    path: 'campaign-performance',
    element: 'products/hyperion/pages/cs-campaign-tracker/CsCampaignTracker',
    page_type_id: 25,
    helmet: 'CS Campaign Tracker',
    DEFAULT: {
      path: '',
      element: 'products/hyperion/pages/cs-campaign-tracker/components/table/CsCampaignTrackerTable',
    },
    ALL_REGIONS: {
      path: 'all',
    },
    CENTRAL: {
      path: 'central',
    },
    EAST: {
      path: 'east',
    },
    WEST: {
      path: 'west',
    },
  },
  DS_CAMPAIGN_TRACKER: {
    path: 'ds-campaign-performance',
    element: 'products/hyperion/pages/ds-campaign-tracker/DsCampaignTracker',
    page_type_id: 26,
    helmet: 'DS Campaign Tracker',
  },
  CUSTOM_REPORT: {
    path: 'reports/:report_id/report',
    element: 'products/hyperion/pages/custom-report/CustomReportPage',
    page_type_id: null,
    helmet: 'Custom Report',
  },
  INBOUND_REPORT_INBOX: {
    path: 'inbound-report-inbox',
    element: 'products/hyperion/pages/inbound-report-inbox/InboundReportInboxPage',
    page_type_id: 23,
    helmet: 'Inbound Report Inbox',
  },
  IMPORT_CONFIGURATIONS: {
    path: 'import-configurations',
    element: 'products/hyperion/pages/import-configurations/ImportConfigurationsPage',
    page_type_id: 24,
    helmet: 'Import Configurations',
  },
  IMPORT_CONFIGURATION: {
    path: 'import-configurations/:import_config_id',
    element: 'products/hyperion/pages/import-configuration/template/ImportConfigurationTemplate',
    page_type_id: 37,
    helmet: 'Import Configuration',
    DEFAULT: {
      path: '',
      element: 'products/hyperion/pages/import-configuration/folders/report-history/ReportHistoryPage',
      page_type_id: null,
    },
    MAPPINGS: {
      path: 'mappings',
      element: 'products/hyperion/pages/import-configuration/folders/mappings/MappingsPage',
      page_type_id: null,
    },
    REPORT_HISTORY: {
      path: 'report-history',
      element: 'products/hyperion/pages/import-configuration/folders/report-history/ReportHistoryPage',
      page_type_id: null,
    },
    UNJOINED_ROWS: {
      path: 'unjoined-rows',
      element: 'products/hyperion/pages/import-configuration/folders/unjoined-rows/UnjoinedRowsPage',
      page_type_id: null,
    },
  },
  DSP_LINE_ITEM_DEAL_MAPPINGS: {
    path: 'dsp-line-item-deal-mappings',
    element: 'products/hyperion/pages/dsp-line-item-deal-mappings/DspLineItemDealMappingsPage',
    page_type_id: 44,
    helmet: 'DSP Line Item - Deal Mappings',
  },
  PERSONAS: {
    path: 'personas',
    element: 'products/hyperion/pages/personas/PersonasPage',
    page_type_id: null,
    helmet: 'Personas',
  },
  PERSONA_VERSIONS: {
    path: 'personas/:persona_config_id',
    element: 'products/hyperion/pages/persona-versions/PersonaVersionsPage',
    page_type_id: null,
    helmet: 'Persona Versions',
  },
  PERSONA: {
    path: 'personas/profiles/:persona_id',
    element: 'products/hyperion/pages/persona/PersonaPage',
    page_type_id: null,
    helmet: 'Persona',
  },
};

export const GEMINI = {
  APPLICATION: {
    path: '/applications/core',
    element: '',
    page_type_id: null,
    helmet: 'Core Application',
  },
  DEFAULT: {
    path: '',
    element: 'products/gemini/pages/campaigns/CampaignsPage',
    page_type_id: 39,
    helmet: 'Core Campaigns',
  },
  CAMPAIGNS: {
    path: 'campaigns',
    element: 'products/gemini/pages/campaigns/CampaignsPage',
    page_type_id: 39,
    helmet: 'Core Campaigns',
  },
  CAMPAIGN: {
    path: 'campaigns/:campaign_id',
    element: 'products/gemini/pages/campaign/template/CampaignTemplate',
    page_type_id: 40,
    helmet: 'Core Campaign',
    DEFAULT: {
      path: '',
      element: 'products/gemini/pages/campaign/folders/performance/PerformancePage',
      page_type_id: null,
    },
    PERFORMANCE: {
      path: 'performance',
      element: 'products/gemini/pages/campaign/folders/performance/PerformancePage',
      page_type_id: null,
    },
    DCPM: {
      path: 'dcpm',
      element: 'products/gemini/pages/campaign/folders/dcpm/DcpmPage',
      page_type_id: null,
    },
    ATTRIBUTIONS: {
      path: 'attributions',
      element: 'products/gemini/pages/campaign/folders/attributions/AttributionsPage',
      page_type_id: null,
    },
    NOTES: {
      path: 'notes',
      element: 'products/gemini/pages/campaign/folders/campaign-notes/CampaignNotesPage',
      page_type_id: null,
    },
  },
};

export const POLLUX = {
  APPLICATION: {
    path: '/applications',
    element: 'products/pollux/pages/application/template/ApplicationTemplate',
    page_type_id: null,
  },
  FILTERS: {
    path: 'filters',
    element: 'products/pollux/pages/filters/FiltersPage',
    helmet: 'Filters',
    page_type_id: null,
  },
  FILTER: {
    path: 'filters/:filter_id',
    element: 'products/pollux/pages/filter/FilterPage',
    helmet: 'Filter',
    page_type_id: null,
  },
};
