import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import { Outlet } from 'react-router';
import { useClientContext } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { ManageOrionAdvertiserDomainList } from 'products/orion/modals/manage-orion-advertiser-domain-list/ManageOrionAdvertiserDomainList';
import { setTitanAdvertisers } from 'products/titan/operators/advertisers/slices';
import { setTitanCampaignNoteCategoriesList } from 'products/titan/operators/campaign-note-categories-list/slices';
import { setTitanCampaignRolePersons } from 'products/titan/operators/campaign-role-persons/slices';
import { setTitanCampaignRolesList } from 'products/titan/operators/campaign-roles-list/slices';
import { setTitanCreativeTypesList } from 'products/titan/operators/creative-types-list/slices';
import { setTitanDeliverySourcesList } from 'products/titan/operators/delivery-sources-list/slices';
import { setTitanInventorySourcesList } from 'products/titan/operators/inventory-sources-list/slices';
import { setTitanKpiTypesList } from 'products/titan/operators/kpi-types-list/slices';
import { setTitanSalesforceFlights } from 'products/titan/operators/salesforce-flights/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Content } from 'components/layout/content/Content';
import { Menu } from 'components/menu/Menu';

import cn from 'products/orion/template/ApplicationTemplate.module.scss';

export default () => {
  const dispatch = useAppDispatch();
  const { Libra, Titan, handleError } = useClientContext();
  const { orion } = useAppSelector(selectSettings);

  useEffect(() => {
    const getData = async () => {
      try {
        const [
          titan_inventory_sources,
          titan_advertisers,
          titan_kpi_types,
          titan_creative_types_list,
          titan_campaign_role_persons,
          titan_campaign_roles,
          titan_delivery_sources,
          titan_campaign_note_categories,
          titan_salesforce_flights,
        ] = await Promise.all([
          Titan.getTitanInventorySourcesList(),
          Titan.getTitanAdvertisers(),
          Titan.getTitanKpiTypesList(),
          Titan.getTitanCreativeTypesList(),
          Titan.getTitanCampaignRolePersons(),
          Titan.getTitanCampaignRolesList(),
          Titan.getTitanDeliverySourcesList(),
          Titan.getTitanCampaignNoteCategoriesList(),
          Titan.getTitanSalesforceFlights(),
        ]);
        batch(() => {
          dispatch(setTitanInventorySourcesList(titan_inventory_sources));
          dispatch(setTitanAdvertisers(titan_advertisers));
          dispatch(setTitanKpiTypesList(titan_kpi_types));
          dispatch(setTitanCreativeTypesList(titan_creative_types_list));
          dispatch(setTitanCampaignRolePersons(titan_campaign_role_persons));
          dispatch(setTitanCampaignRolesList(titan_campaign_roles));
          dispatch(setTitanDeliverySourcesList(titan_delivery_sources));
          dispatch(setTitanCampaignNoteCategoriesList(titan_campaign_note_categories));
          dispatch(setTitanSalesforceFlights(titan_salesforce_flights));
        });
      } catch (err) {
        handleError(err);
      }
    };

    if (orion) {
      getData();
    }
  }, [dispatch, orion, Libra, Titan, handleError]);

  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Outlet />
      </Content>
      <ManageOrionAdvertiserDomainList />
    </div>
  );
};
